import React from "react"
import Layout from "../../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import SurfaceSelector from "../../components/paintingtools/surfaceselector"

import * as styles from "../../styles/pages/paintingtools.module.css"

const imgbanner = "../../images/paintingtools/cypbanner.jpg"

const ChoosepaintPage = () => (
	<Layout activePath='paintingtools/choosepaint' pageTitle="Choose Your Paint" pageDescription="Lost on what paint products to use? Get recommendations here.">
		<div className={styles.banner}>
			<StaticImage layout={"fullWidth"}  src={imgbanner} alt={"Choose Your Paint"} />
		</div>
		<h1 className={"text-centered textcolor-primary "}>Choose Your Paint</h1>
		<div className={"text-centered textcolor-primary "+styles.description}>
			<div>Lost on what paint products to use?</div>
			<div>Get recommendations here.</div>
		</div>
		<SurfaceSelector selectmode={"cyp"} />
	</Layout>
)

export default ChoosepaintPage
